import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { getlocationlistmapId } from '../API';
import Header from './Header';
import { BottomNavigation, BottomNavigationAction, InputLabel, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

function SelectDelivery() {
    const [deliverylocation,setdeliverylocation] =useState()
    const [activenextbtn ,setactivenextbtn] =useState(false)
    const {t}  = useTranslation() 
    useEffect(()=>{
        getlocationlistmapId().then((res)=>{
             console.log('Location List Map Id', res)
             setdeliverylocation(res.data.data)
        }).catch((err)=>{
             console.log('Error in Map Id Locations',err)
        })
   
      },[])
   const cartData = useLocation()
   const navigate = useNavigate()   

  var dlocation = localStorage.getItem('deliveryLocation')
  const validationSchema = yup.object({
    deliverylocation :yup.string().required('Please Select Delivery location')
})  
  const formik = useFormik({
    initialValues: {
      deliverylocation:'',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      localStorage.setItem('deliveryLocation', values.deliverylocation)
      navigate('/details', {state:{locationName:values.deliverylocation, cartdata:cartData}})
    }
  })
  let newlocation=[]
    return (
        <>
     <div className='MainWhole-wrapper-container'>    
        <Header/>   
      <div className="DetailsPage-Main-wrapper">
         <h2> {t('cart_page_delivery_location')}</h2>
          {console.log('deliverylocation',deliverylocation)}
          <form onSubmit={formik.handleSubmit} style={{height:'100vh'}}> 
        <div className='Select_delivery_page_inner_wrapper'> 
        {/* <p className='Select_delivery_page_InnerHeading'>COUNCOURSE B</p>  */}
         <div className='Select_delivery_locations_list_wrapper'>  
          {/* {deliverylocation?.map((item)=>{
            if(item.locationType ==="DROPOFF" && item.status ==="ACTIVE")
              return (
                <div className={dlocation===item.locationName ? 'Select_delivery_location_delivery_name delivery_active':"Select_delivery_location_delivery_name"} onClick={()=>handlelocationclick(item.locationName)}> 
                   {item.locationName}
                </div> )
        })} */}
             <BottomNavigation
                showLabels
                name="deliverylocation"
                value={formik.values.deliverylocation}
                onChange={(event, newValue) => {
                    formik.setFieldValue('deliverylocation',newValue)
                }}
                style={{ flexWrap:'wrap', position:'relative', display:'inline-block', textAlign:'start' , height:'auto',background:' transparent'}}
                error={formik.touched.deliverylocation && Boolean(formik.errors.deliverylocation)}
                helperText={formik.touched.deliverylocation && formik.errors.deliverylocation} 
            >
               <h4 className='delivery_location_page_content_heading'> Concourse B</h4>

              
             
                {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" && item.locationName.charAt(0)==='B' )
                    {
                     return newlocation.push(item)
                      // return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 9px',backgroundColor:'white' , margin:'10px !important'}} />
                    } 
                })}
              {(()=>{
                newlocation=  newlocation.reverse()
               })()}

               {console.log('newlocationnewlocation',newlocation)} 
                {newlocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" && item.locationName.charAt(0)==='B' )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 9px',backgroundColor:'white' , margin:'10px !important'}} />
                    } 
                })}
                <br/>
                <h4 className='delivery_location_page_content_heading'> Concourse D</h4>
                 {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" && item.locationName.charAt(0)==='D' )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 9px',backgroundColor:'white' , margin:'10px !important'}} />
                    } 
                })}
            </BottomNavigation>
            {(formik.touched.deliverylocation &&formik.errors.deliverylocation)? 
          <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> {t('Detail_page_please_select_delivery_location')}</p>:null }
      </div>  
        </div>
        <button className="ItemList_Page_Next_button" type="submit"> {t('Item_list_two_page_Nextbtn_text')} </button> 
       
        </form>
    </div>
        
   </div> 
    </>  
  )
}

export default SelectDelivery
