import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { DecrementCount, IncrementCount } from '../redux/Actions';
import { relayitemslist } from '../Data/ItemList'
import Product from './Product/Product';
import { useTranslation } from "react-i18next";
import Footer from './Footer';


function ItemsList() {
    const checkvar=true
    const [totalItems, settotalItems]=useState(0)
    const [totalcost,settotalcost] =useState(0)
    const data = useLocation()
    const navigate= useNavigate()
    const dispatch =useDispatch()
    const cartData=[]  
    const countdata=useSelector(state=> state.ItemCounter)
    const listdata=useSelector(state=> state.ItemsListReducer)
    const handleNextButton=()=>{
     listdata?.itemslist?.map((items)=>{
         if(items.count>0){
          cartData.push(items)                
        }       
})
    setTimeout(()=>{
        navigate('/details', {state:cartData})
        //  window.location.reload()
    },2000)
   }
     const handlecartupdate=(id)=>{
               listdata?.itemslist?.map((items)=>{
                if(items.count>0){
                   settotalItems(totalItems+1)
                    console.log('totalItems',totalItems)
                    // settotalcost(totalcost+items.price)
                  }       
              })
     }
    const handlecartupdatetwo=(id)=>{
        listdata?.itemslist?.map((items)=>{
            if(items.count>0){
               settotalItems(totalItems=> totalItems-1)
                console.log('totalItems',totalItems)
                for(var i=0;i<items.count ;i++){
                settotalcost(totalcost=> totalcost-items.price)
              }}       
          })
    }

    const { t } = useTranslation()     
    return (
    <> 
     <div className='MainWhole-wrapper-container'>  
    <Header/>
    {console.log('countdata',countdata)}
    <div className='ItemListMain-Wrapper'>
         <div className='ItemList_Page_Logos_Wrapper'> 
            <div className="ItemList_Logo_div_one"> 
                <img src="/assets/images/xBridge_logo (1).png"/>
            </div>
            <div className="ItemList_Logo_div_two">       
                <img src="/assets/images/pittsburghlogo.png"/>
           </div>
        </div>
        <div className="Logos_Below_div_wrapper"> 
           <p> Help us test a Robot and get a free drink</p>
        </div> 
        <div className="ItemsListPage-ItemsList-wrapper-whole"> 
                    {relayitemslist?.map(items=>{
                        return (
                            <>
                            <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src={items.img} className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> {items.name}</h4>
                                                    <p> €{items.price}</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                               dispatch(DecrementCount(items, items.id))
                               handlecartupdatetwo(items.id)
                            }
                            }> -</button>
                              <input type="number" readOnly value={items.count} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 cartData.push(items)
                                 handlecartupdate(items.id)
                                 dispatch(IncrementCount(items, items.id))
                                  console.log('listdata.itemslist',listdata.itemslist)
                                }}> +</button>  
                            </div>
                         </div>
                         {/* <Product data={items}/> */}
              
                         </>
                        )
                    })}        
          
        </div>
   
    </div>
    {countdata.counter >0 ?<button className="ItemList_Page_Next_button" onClick={handleNextButton}> Next </button>:null }
     
    <Footer/>
   </div> 
   </>
  )
}

export default ItemsList