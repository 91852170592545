export const IncrementCount =(data , id)=>{
    return{
        type: "INCREMENT_COUNT",
        payload:{data,id}
    }
}

export const DecrementCount =(data ,id)=>{
    return{
        type: "DECREMENT_COUNT",
        payload:{data,id }
    }
}


export const ItemsList =(data)=>{
  return {
    type:"ITEMS_LIST_STORING",
    payload:data
  }
}
export const otpaction=(data)=>{
  return{
    type:"OTP_NUMBER",
    payload:data
  }
}