import React from 'react'

function LandingPageHeader() {
  return (
    <div className='Landing_page_Header_image_wrapper'>
        <img src="/assets/images/pittsburghlogo.png"/>
    </div>
  )
}

export default LandingPageHeader