import React, { useEffect, useState } from 'react'
import Header from './Header'
import $ from 'jquery'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { emphasize } from '@mui/material';
import { getdeliverytime, OrderQrcode, sendmsglocation } from '../API';

function Congratulations() {
  
  const [remaintime, setremaintime]=useState()
  var rString=''
  const orderData =useLocation()
  const [newstring, setnewstring] =useState()
   useEffect(()=>{

    generateBarCode(orderData.state.newstring)
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hrs= today.getHours(); // => 9
    let mins=today.getMinutes(); // =>  30
    var ampm = hrs >= 12 ? 'pm' : 'am';
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12
    if (dd < 10){
      dd = '0' + dd;
    } 
    if (mm < 10){
      mm = '0' + mm;
    } 
    if(hrs < 10){
      hrs ='0'+hrs
    }
    if(mins < 10){
      mins ='0'+mins
    }
    const formattedToday = mm + '-' + dd + '-' + yyyy + '\xa0\xa0\xa0\xa0\xa0'+hrs +":" + mins +'\xa0\xa0'+ ampm;
    document.getElementById('DATE').innerHTML = formattedToday;
   setInterval(()=>{
    getdeliverytime(orderData.state.newstring).then((res)=>{
      // console.log('Response timer',res)
      setremaintime(res.data.data[0].deliveryTime)
     }).catch((err)=>{
         console.log(err)
     })
   }, 4000)  
  
   },[])

    function generateBarCode(orderId)
    {
        var url = 'https://api.qrserver.com/v1/create-qr-code/?data=' + orderId + '&amp;size=50x50';
        $('#barcode').attr('src', url);
        OrderQrcode(orderId,url).then((res)=>{
             console.log('QRlInk reposne',res)
        }).catch((err)=>{
          console.log(err)
        })
        return rString
    }
 
    const { t } = useTranslation() 
  //   function startTimer(duration, display) {
  //     var timer = duration, minutes, seconds;
  //     setInterval(function () {
  //         minutes = parseInt(timer / 60, 10)
  //         seconds = parseInt(timer % 60, 10);
  
  //         minutes = minutes < 10 ? "0" + minutes : minutes;
  //         seconds = seconds < 10 ? "0" + seconds : seconds;
  
  //         display.textContent = minutes + ":" + seconds;
  
  //         if (--timer < 0) {
  //             timer = duration;
  //         }
  //     }, 1000);
  // }
  
  // window.onload = function () {
  //     var fiveMinutes = 60 * 20,
  //         display = document.querySelector('#time');
  //     startTimer(fiveMinutes, display);
  // };
    return (
    
     <>
      <div className='MainWhole-wrapper-container'>  
      {console.log('orderIdorderId',orderData)}
     <Header/>
    <div className='Congratulation-Main-wrapper'>
       <div className='Congratulation-content-div-one-wrapper'>
             {/* <div className='Congratulation-content-div-one-wrapper-inner-one'> 
               <img src="/assets/images/order-placed.gif"/>    
            </div> */}
           <div className='Congratulation-content-div-one-wrapper-inner-two'>
                <h1> 
                  {t('congratulations_page_heading')}
                  </h1>
                <p> Estimated time for delivery is <span id="">{remaintime}</span> minutes. </p> 
                
           </div>
       </div>
      <div className='QR-Code-wrapper-whole'> 
          <div className='QR-Code-wrapper-whole-inner-one'>
            <h4> {t('congratulations_page_your_invoice')}</h4>
            {/* <p>{t('congratulations_page_your_invoice_content')}</p> */}
          </div>
          <div className='QR-Code-main-inner'> 
          {/* <input id="text" type="text" 
            value='' style={{Width:'20%'}}
            onChange={generateBarCode} />  */}

      <img id='barcode' 
            src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100" 
            alt="" 
            title="Congratualtions" 
            width="300" 
            height="300"
            style={{objectFit:'cover'}} />
         </div>      
     </div>
     <div className='Congratulation-page-order-wrapper-below-QR'> 
       <div> 
          <h5 style={{textAlign:'left'}}> {t('congratulations_page_OrderID')}:</h5>
          <p> {orderData.state.newstring} </p>
       </div>    
       <div> 
          <h5 style={{textAlign:'left'}}>{t('congratulations_page_Order_date')}:</h5>
          <p id="DATE">  </p>
       </div>
       {/* <div> 
       <h5> {t('congratulations_page_Payment_Mode')}:</h5>
          <p> {t('congratulations_page_Payment_Mode_content')}</p>
       </div> */}
     </div> 
     <div className='QR-Code-wrapper-whole-inner-one'>
           {/* <h4>Estimated time for delivery<br />is XX minutes.</h4> */}
          </div>
     <div className="Congratulation-page-invoice-wrapper"> 
            {/* <table className="Congratulation-page-invoice-wrapper-table-whole">
                <thead>
                   <th style={{textAlign:'left',paddingBottom: '12px',fontSize: '14px'}}>{t('congratulations_page_table_item')} </th>
                   <th style={{paddingBottom: '12px',fontSize: '14px'}}>{t('congratulations_page_table_Quantity')} </th>
                   <th style={{paddingBottom: '12px',fontSize: '14px'}}>{t('congratulations_page_table_Price')} </th>
               </thead>
               <tbody>
                  <tr> 
                    <td style={{textAlign:'left'}}> Acqua Min.Naturale 0,5 LT</td>
                    <td> X 1</td>
                    <td> 1,70</td>
                 </tr>
               </tbody>
            </table> */}
          {/* <div className='Congratulation-page-subtotal-wrapper'> 
            <h4> {t('congratulations_page_table_Subtotal')} : <span> 1,70</span></h4>
            <h4> {t('congratulations_page_table_Delivery_charges')} : <span> 1,70</span></h4>
         </div>  
         <div  className='Congratulation-page-total-wrapper'>
          <p> {t('congratulations_page_table_Total')} : <span> 1,70</span></p>
         </div>    */}
     </div>
    </div>
   </div> 
   <Footer/>
    </>
  )
}

export default Congratulations